import React from "react";
import Layout from "../components/layout";
import InGrid from "../components/in-grid";



const ReserveTermsPage = () => (
  <Layout currentPage="reserve-terms">
    <div className="container" style={{paddingLeft: "0", paddingBottom: "100px", paddingTop: "100px"}}>
     <div className="col-md-12">
      <InGrid columns={9}>
        <div className="aligned-left legalcopy">
        <h1>Reserving Your Uno: Terms</h1>
        <br></br>
        <p>By reserving your Uno Cup you acknowledge that you’re interested in becoming a user of the service. You are not purchasing a product or subscribing to our services.
        </p>
        <p>We will contact you when Uno launches in your area to complete the payment process. You may change your cup color and size before completing your purchase.
        </p>
        <p>A reservation does not constitute a commitment on your part to purchase, nor does it guarantee that we will offer the product for sale. While we appreciate your interest and hope that we can serve as many people as possible, Uno Cup, Inc. cannot at this time guarantee that all reservations will be accommodated. 
        </p>
        <p>Product appearance and features may vary. 
        </p>
        <p>We may prioritize certain reservations based on location.
        </p>
        <p>We respect your privacy and will not share your personal data with other entities.</p>
        </div>
      </InGrid>
      </div>
    </div>
  </Layout>
);

export default ReserveTermsPage;